const kontaktForm = document.getElementById("kontakt-form");
const formularForm = document.getElementById("formular-form");

const success_schaden_kontakt = kontaktForm.querySelector(
  "div#success_schaden"
);
const success_schaden_formular = formularForm.querySelector(
  "div#success_schaden"
);

const url = `/send-email`;

kontaktForm.addEventListener("submit", handleFormSubmit);
formularForm.addEventListener("submit", handleFormSubmit);

async function handleFormSubmit(event) {
  event.preventDefault();
  const form = event.currentTarget;

  try {
    /**
     * This takes all the fields in the form and makes their values
     * available through a `FormData` instance.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData
     */
    const formData = new FormData(form);
    formData.append("form_type", form.id);

    /**
     * postFormDataAsJson is defined in the next step.
     */

    await postFormDataAsJson({ url, formData });
    form.reset();
    display_message(true, form.id);
  } catch (error) {
    display_message(false, form.id);
  }
}

async function postFormDataAsJson({ url, formData }) {
  /**
   * We can't pass the `FormData` instance directly to `fetch`
   * as that will cause it to automatically format the request
   * body as "multipart" and set the `Content-Type` request header
   * to `multipart/form-data`. We want to send the request body
   * as JSON, so we're converting it to a plain object and then
   * into a JSON string.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
   */
  const plainFormData = Object.fromEntries(formData.entries());
  const formDataJsonString = JSON.stringify(plainFormData);
  const fetchOptions = {
    /**
     * The default method for a request with fetch is GET,
     * so we must tell it to use the POST HTTP method.
     */
    method: "POST",
    /**
     * These headers will be added to the request and tell
     * the API that the request body is JSON and that we can
     * accept JSON responses.
     */
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    /**
     * The body of our POST request is the JSON string that
     * we created above.
     */
    body: formDataJsonString,
  };

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }
}

function display_message(success, type) {
  switch (type) {
    case "kontakt-form":
      assign_message(success, success_schaden_kontakt);
      break;
    case "formular-form":
      assign_message(success, success_schaden_formular);
      break;
  }
}

function assign_message(success, element) {
  if (success) {
    element.innerHTML = "Ihre Nachricht wurde gesendet.";
    element.classList.add("success");
  } else {
    element.innerHTML =
      "<strong>Leider besteht gerade keine Verbindung zum Server. Versuchen Sie es bitte später erneut.";
    element.classList.add("fail");
  }
}
