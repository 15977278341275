// SCSS
import "index.scss";

// JS Components
import "./components/navbar";
import "./components/modals";
import "./components/kontakt";

import { showModal, formularDesignEdit, modalLinks } from "./components/modals";

// Import all JavaScript & CSS files from src/_components
const componentsContext = require.context(
  "bridgetownComponents",
  true,
  /\.(js|scss)$/
);
componentsContext.keys().forEach(componentsContext);

document.addEventListener("DOMContentLoaded", () => {
  let link = window.location.href;
  let id = link.split("/");
  id = id[id.length - 1];
  id = id.substring(id.indexOf("#"));

  modalLinks.forEach((link) => {
    if (link.hash == id) {
      showModal(id);
      formularDesignEdit(id);
    }
  });
});
