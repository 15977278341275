const lang = document.documentElement.lang;
const navbar = document.getElementById("navbar");
const brandLink = document.querySelector("div.brand a");
const navLinks = document.querySelectorAll("div.links a");
const menuButton = document.querySelector("nav button.menu");

if (lang == "ru") {
  navbar.classList.add("ru");
} else if (lang == "uk") {
  navbar.classList.add("uk");
}

window.onscroll = function () {
  shrinkNavbar(lang);
};

function shrinkNavbar(lang) {
  if (document.documentElement.clientWidth > 768) {
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      switch (lang) {
        case "de":
          navbar.style.height = "8vh";
          brandLink.style.fontSize = "1.4rem";
          break;
        case "ru":
          navbar.classList.remove("ru");
          navbar.style.height = "8vh";
          brandLink.style.fontSize = "1.1rem";
          navLinks.forEach((link) => {
            link.style.fontSize = "0.85rem";
          });
          break;
      }
    } else {
      switch (lang) {
        case "de":
          navbar.style.height = "11vh";
          brandLink.style.fontSize = "1.85rem";
          break;
        case "ru":
          navbar.classList.add("ru");
          navLinks.forEach((link) => {
            link.style.fontSize = "1.25rem";
          });
          navbar.style.height = "fit-content";
          brandLink.style.fontSize = "1.85rem";
          break;
      }
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  // Get all the links with an ID that starts with 'sectionLink'
  const listOfLinks = document.querySelectorAll("#navbar a");

  listOfLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      if (event.target.tagName != "IMG") {
        event.preventDefault();
      }
      // Toggle highlight on and off when we click a link
      listOfLinks.forEach((link) => {
        if (link.classList.contains("highlighted")) {
          link.classList.remove("highlighted");
        }
      });

      if (!link.href.includes("header")) {
        link.classList.add("highlighted");
      }

      // Get the element where to scroll
      let ref = link.href.split("/");
      ref = ref[ref.length - 1];
      ref = ref.substring(ref.indexOf("#"));

      window.scroll({
        behavior: "smooth",
        left: 0,
        // Top gets the distance from the top of the page of our target element
        top:
          (document.querySelector(ref) &&
            document.querySelector(ref).offsetTop) ||
          0,
      });
      window.history.pushState({}, "", ref);
    });
  });
});

menuButton.addEventListener("click", function (event) {
  event.preventDefault();

  const links = document.querySelector("nav div.links");

  if (links.classList.contains("show")) {
    links.classList.remove("show");
  } else {
    links.classList.add("show");
  }
});
