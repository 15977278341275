const modalLinks = document.querySelectorAll("a.modal-open");

function formularDesignEdit(modal) {
  if (modal == "#schadenregulierung") {
    const labels = document.querySelectorAll("#schadenregulierung label");

    labels.forEach(function (label) {
      label.style.marginLeft = `${(label.clientWidth / 2) * -1}px`;
    });
  }
}

function showModal(modal) {
  document.querySelector(modal).classList.add("show");
  document.body.style.overflow = "hidden";

  window.history.pushState({}, "", modal);
}

modalLinks.forEach(function (link) {
  link.addEventListener("click", function (event) {
    event.preventDefault();

    let modal = link.href.split("/");
    modal = modal[modal.length - 1];
    modal = modal.substring(modal.indexOf("#"));

    showModal(modal);

    formularDesignEdit(modal);
  });
});

document.querySelectorAll("div.modal .close").forEach(function (button) {
  button.addEventListener("click", function (event) {
    event.preventDefault();

    for (let elem of document.getElementsByClassName("show")) {
      elem.classList.remove("show");
    }

    document.body.style.overflow = "auto";

    window.history.back();
  });
});

export { showModal, formularDesignEdit, modalLinks };
